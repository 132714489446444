// Imports
import { FC } from 'react'

// Icon Props
interface GoalTargetIconProps {
  sizeInPixels: number
  fillColour?: string
  className?: string
}

const GoalTargetIcon: FC<GoalTargetIconProps> = ({
  sizeInPixels,
  fillColour = 'currentColor',
  className,
}) => {
  return (
    <svg
      className={className}
      width={sizeInPixels}
      height={sizeInPixels}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <g>
        <path
          d="m58.84 41.16c4.8789 4.8828 4.8789 12.797 0 17.68-4.8828 4.8789-12.797 4.8789-17.68 0-4.8789-4.8828-4.8789-12.797 0-17.68 4.8828-4.8789 12.797-4.8789 17.68 0z"
          fill={fillColour}
        />
        <path
          d="m18.75 50c0-17.258 13.992-31.25 31.25-31.25s31.25 13.992 31.25 31.25-13.992 31.25-31.25 31.25-31.25-13.992-31.25-31.25zm31.25-25c-13.809 0-25 11.191-25 25s11.191 25 25 25 25-11.191 25-25-11.191-25-25-25z"
          fillRule="evenodd"
          fill={fillColour}
        />
        <path
          d="m50 0c-27.613 0-50 22.387-50 50s22.387 50 50 50 50-22.387 50-50-22.387-50-50-50zm-43.75 50c0-24.164 19.586-43.75 43.75-43.75s43.75 19.586 43.75 43.75-19.586 43.75-43.75 43.75-43.75-19.586-43.75-43.75z"
          fillRule="evenodd"
          fill={fillColour}
        />
      </g>
    </svg>
  )
}

export default GoalTargetIcon
